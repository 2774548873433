
















































import Vue from "vue";
import { Toast, Dialog } from "vant";
import { mapState } from "vuex";
import { dispatch, RootState, Address, ListQuery } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      address: {} as Address,
      addressId: 0,
    };
  },
  computed: mapState({
    list(state: RootState) {
      return state.addresses.list;
    },
    query(state: RootState) {
      return state.addresses.query;
    },
    count(state: RootState) {
      return state.addresses.count;
    },
    isSelect() {
      return !!this.$route.query.redirect;
    },
  }),
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .addressesGetList(query)
        .then((res) => {
          this.loading = false;
          // if (!res.count) {
          //   this.$router.push({ path: this.$paths.addressesAdd });
          // }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onAdd() {
      this.$router.push(this.$paths.addressesAdd);
    },
    onItemClick(address: Address) {
      this.addressId = Number(address.id);
    },
    onEdit(address: Address) {
      this.$router.push(this.$paths.addresses + "/" + address.id);
    },
    onDelete(address: Address) {
      Dialog.confirm({
        title: "提示",
        message: "确认删除收货地址？",
        beforeClose: (action, done) => {
          if (action === "confirm") {
            dispatch.addressesDelete(address).then(() => {
              Toast("收货地址删除成功");
              this.onRefresh();
              done();
            });
          } else {
            done();
          }
        },
      });
    },
    onSave() {
      if (this.addressId) {
        if (this.isSelect) {
          this.$router.replace({
            path: String(this.$route.query.redirect),
            query: {
              address: String(this.addressId),
            },
          });
        } else {
          this.$router.back();
        }
      } else {
        Toast("请选择一个收货地址");
      }
    },
  },
  mounted() {
    this.onRefresh();

    const { address } = this.$route.query;
    if (address) {
      this.addressId = Number(address);
    }
  },
});
